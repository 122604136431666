<template>
  <b-input-group class="search-form">
    <b-form-input
      v-model="searchString"
      :placeholder="isXsBreakpoint ? 'Search' : 'Search for articles and books'"
      data-testid="searchInput"
      autocomplete="off"
      @focus="onFocus"
      @blur="onBlur"
      @keyup.enter="onEnter"
    />
    <b-input-group-append>
      <b-button
        v-show="searchString !== ''"
        variant="light"
        data-testid="clearButton"
        @click="onClickClearButton"
      >
        <img
          src="../../assets/svg/close-large.svg"
          alt="Close"
          class="close-icon"
        >
      </b-button>
      <b-button
        variant="light"
        class="search-button"
        data-testid="searchButton"
        @click="onClickSearchButton"
      >
        <img
          src="../../assets/svg/search.svg"
          alt="Search"
          class="search-icon"
        >
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupAppend, BButton } from 'bootstrap-vue'
import { BreakPoints } from '../../constants/AppConstants'

export default {
  name: 'SearchForm',
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton
  },
  data: function () {
    return {
      searchString: '',
      isXsBreakpoint: false
    }
  },
  created () {
    this.doSearchSettings()
    window.addEventListener('resize', this.doSearchSettings)
  },
  destroyed () {
    window.removeEventListener('resize', this.doSearchSettings)
  },
  methods: {
    onClickSearchButton: function (e) {
      this.$emit('input', { searchString: this.searchString })
    },
    onClickClearButton: function (e) {
      this.searchString = ''
    },
    onEnter: function () {
      this.$emit('input', { searchString: this.searchString })
    },
    onFocus: function () {
      this.$emit('focus')
    },
    onBlur: function () {
      this.$emit('blur')
    },
    doSearchSettings () {
      this.isXsBreakpoint = window.innerWidth < BreakPoints.mdMin
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  height: 34px;

  @include placeholder {
    color: $grey-11;
    font-size: 16px;
  }

  .input-group-append {
    background: $white;

    button {
      border: 0;
      border-radius: 0;
      border-left: 0;
      box-shadow: none;
      background: $white;
      padding-right: 14px;
      padding-top: 0;

      &.search-button {
        background: $grey-1;
      }

      &:hover,
      &:focus {
        background: $grey-1;
      }

      img {
        margin-top: 1px;
      }
    }
  }

  input {
    background: $white;
    color: $black;
    border-color: transparent;
    border-radius: 0;
    border-right: 0;
    height: calc(1.55em + 0.75rem + 2px);
    padding-left: 14px;

    &:focus {
      box-shadow: none;
      border-color: $white;

      +.input-group-append {
        border-color: $white;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .search-form {
    height: 32px;

    input {
      font-size: 14px;
      height: 32px;
    }

    .input-group-append {
      height: 32px;

      button {
        padding: 0.2rem 0.75rem;

        .search-icon {
          width: 18px;
          margin-top: -2px;
        }

        .close-icon {
          height: 12px;
          margin-top: -2px;
        }
      }
    }
  }
}
</style>
