import { createServer } from 'miragejs'

// import home from '../mocks/homeSample3.json'

const startMockServer = () => {
  const { VUE_APP_API_BASE_URL: API_BASE_URL } = process.env

  const server = createServer({
    routes () {
      this.passthrough(`${API_BASE_URL}/v2/home`)
      this.passthrough(`${API_BASE_URL}/v2/articles/:articleId`)
    }
  })

  return server
}

export default startMockServer
