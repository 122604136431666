<template>
  <section class="header-navigation-tablet">
    <b-button
      variant="light"
      class="toggle-button"
      data-testid="headerNavigationToggleButton"
    >
      <img
        src="../../assets/svg/hamburger.svg"
      >
    </b-button>
  </section>
</template>

<script>

import { BButton } from 'bootstrap-vue'

export default {
  name: 'HeaderNavigationTablet',
  components: {
    BButton
  }
}
</script>

<style lang="scss" scoped>
.header-navigation-tablet {
  .toggle-button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    line-height: 1;

    &:hover {
      background: transparent;
    }
  }
}
</style>
