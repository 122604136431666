<template>
  <div id="app">
    <top-header />
    <router-view />
  </div>
</template>

<script>
import TopHeader from './components/organisms/TopHeader'
export default {
  name: 'App',
  components: {
    TopHeader
  }
}
</script>
