import axios from 'axios'

class ApiClient {
  /**
   * Create axios client
   * @param baseURL
   */
  static init (baseURL) {
    ApiClient.client = axios.create({
      baseURL
    })
  }
}

export default ApiClient
