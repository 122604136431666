<template>
  <header class="top-header">
    <b-container>
      <b-row no-gutters>
        <b-col>
          <section
            class="header-items"
            :class="{'search-input-focused' : searchInputFocused}"
            data-testid="topHeaderItems"
          >
            <router-link
              to="/"
              rel="Home"
              aria-label="Dummies"
              class="logo"
              data-testid="topHeaderLogo"
            >
              <img
                src="../../assets/svg/logo.svg"
                alt="Logo"
              >
            </router-link>
            <div
              class="d-none d-lg-flex navigation-block"
            >
              <header-navigation />
            </div>
            <div class="search-block">
              <search-form
                class="search-elem"
                @focus="onFocusSearchInput"
                @blur="onBlurSearchInput"
              />
            </div>
            <div
              class="d-block d-lg-none navigation-tablet-block"
            >
              <header-navigation-tablet />
            </div>
          </section>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import HeaderNavigation from '../molecules/HeaderNavigation'
import HeaderNavigationTablet from '../molecules/HeaderNavigationTablet'
import SearchForm from '../molecules/SearchForm'

export default {
  name: 'TopHeader',
  components: {
    BContainer,
    BRow,
    BCol,
    HeaderNavigation,
    HeaderNavigationTablet,
    SearchForm
  },
  data: function () {
    return {
      searchInputFocused: false
    }
  },
  methods: {
    onFocusSearchInput: function () {
      this.searchInputFocused = true
    },
    onBlurSearchInput: function () {
      this.searchInputFocused = false
    }
  }
}
</script>

<style lang="scss" scoped>
.top-header {
  background-color: $yellow-4;
  height: 72px;

  .container {
    padding: 0 40px;
    max-width: $pg-max-width;
    height: inherit;

    .header-items {
      display: flex;

      .logo {
        margin-top: 22px;
        display: inline-block;
      }

      .navigation-block {
        height: 74px;
        flex-grow: 1;
        margin-left: 40px;
      }

      .search-block {
        margin-top: 16px;
        width: 33.33%;
        padding-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .top-header {
    height: 56px;

    .container {
      padding: 0 25px;

      .header-items {
        .logo {
          margin-top: 14px;

          img {
            width: 104px;
          }
        }

        .search-block {
          margin-top: 12px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;

          .search-elem {
            width: calc(90% - 70px);
          }
        }

        .navigation-tablet-block {
          float: right;
          margin-top: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .top-header {
    .container {
      padding: 0 16px;

      .header-items {
        .search-block {
          padding-left: 0;

          .search-elem {
            width: calc(90% - 16px);
          }
        }

        &.search-input-focused {
          .logo {
            display: none;
          }

          .search-block {
            justify-content: flex-start;
            align-items: flex-start;

            .search-elem {
              width: calc(100% - 16px);
            }
          }
        }
      }
    }
  }
}

</style>
