<template>
  <b-navbar
    class="header-navigation"
  >
    <b-navbar-nav>
      <b-nav-item-dropdown
        text="Articles"
        right
      >
        <b-dropdown-item to="#">
          Submenu here
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        text="Books"
        right
      >
        <b-dropdown-item to="#">
          Submenu here
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        text="Collections"
        right
      >
        <b-dropdown-item to="#">
          Submenu here
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { BNavbar, BNavbarNav, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  name: 'HeaderNavigation',
  components: {
    BNavbar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem
  }
}
</script>

<style lang="scss" scoped>
.header-navigation {
  /deep/ {
    .navbar-nav {

      //Hiding dropdown menu for dev purpose, as we dont have drop down menu integrated now
      //Below display none code to be removed while integrating drop down menu
      .dropdown-menu {
        display: none;
      }

      .nav-item {
        margin-right: 46px;

        .nav-link {
          color: $black;

          @extend %typography-h4;

          &:hover {
            color: $black;
          }

          &.dropdown-toggle {
            &::after {
              border: 0;
              top: 17px;
              width: 14px;
              height: 8px;
              position: absolute;
              margin-left: 6px;
              background: transparent url(../../assets/svg/chevron-down-black.svg) no-repeat center center;
            }
          }
        }
      }
    }
  }
}
</style>
