import Vue from 'vue'
import App from './App'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import store from './store'
import startMockServer from './mockServer'
import ApiClient from './api/client/ApiClient'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const { VUE_APP_API_BASE_URL: API_BASE_URL } = process.env

// call startMockServer only if process.env.NODE_ENV === 'development'
// At present we don't have real API in non dev environments,
// hence startMockServer is called without checking process.env.NODE_ENV === 'development'
// Should add process.env.NODE_ENV === 'development' checking once real API is available in non dev environments

startMockServer()

Vue.use(BootstrapVue)
Vue.config.productionTip = false

ApiClient.init(API_BASE_URL)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
