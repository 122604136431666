import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  // Home page
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage')
  },

  // Book Category landing/detail page
  {
    path: '/category/books/:category',
    name: 'BookCategory',
    component: () => import('../views/BookCategoryPage')
  },

  // Article Category landing/detail page
  {
    path: '/category/articles/:category',
    name: 'ArticleCategory',
    component: () => import('../views/ArticleCategoryPage')
  },

  // Collection landing/detail page
  {
    path: '/collection/:collection',
    name: 'Collection',
    component: () => import('../views/CollectionPage')
  },

  // Article detail page
  {
    path: '/article/:category1/:article',
    name: 'Article1',
    component: () => import('../views/ArticlePage')
  },
  {
    path: '/article/:category1/:category2/:article',
    name: 'Article2',
    component: () => import('../views/ArticlePage')
  },
  {
    path: '/article/:category1/:category2/:category3/:article',
    name: 'Article3',
    component: () => import('../views/ArticlePage')
  },

  // Book detail page
  {
    path: '/book/:category1/:book',
    name: 'Book1',
    component: () => import('../views/BookPage')
  },
  {
    path: '/book/:category1/:category2/:book',
    name: 'Book2',
    component: () => import('../views/BookPage')
  },
  {
    path: '/book/:category1/:category2/:category3/:book',
    name: 'Book3',
    component: () => import('../views/BookPage')
  },

  // Author detail page
  {
    path: '/author/:author',
    name: 'Author',
    component: () => import('../views/AuthorPage')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
